const formatPhoneNumber = (
  phoneNumber: string | null | undefined
): string | null => {
  if (!phoneNumber) {
    return null;
  }
  const result = /^(\d{3})?(\d{3})(\d{4})$/.exec(phoneNumber);
  if (!result || !result.length) {
    return null;
  }
  const localNumber = `${result[2]}-${result[3]}`;
  return result[1] ? `(${result[1]}) ${localNumber}` : localNumber;
};

export { formatPhoneNumber };

const formatName = (name: string | null | undefined): string | null => {
  if (!name || name.length < 1) {
    return null;
  }

  let result = ``;
  const aWords = name.split(' ');

  aWords.forEach((w, i) => {
    if (i > 0) result += ` `;

    result += `${w.substring(0, 1).toUpperCase()}${w
      .substring(1, w.length)
      .toLowerCase()}`;
  });

  if (!result || !result.length) {
    return null;
  }

  return result;
};

export { formatName };
