import { Box, CircularProgress } from '@chakra-ui/react';
import React from 'react';

import { ChapterEventResponse } from '../../../apis';
import { WLBox, WLDivider, WLH2, WLText } from '../../../design-library';
import { DividerColorScheme } from '../../../design-library/Divider';
import { useChapterEvents } from '../../../hooks/chapter.hooks';
import { chapterEventDateDisplay } from '../utils';

interface EventsProps {
  state: string | null | undefined;
  chapterNumber: number | null | undefined;
}

interface EventProps {
  data: ChapterEventResponse;
}

const Event: React.FC<EventProps> = ({ data }) => {
  const dttm = React.useMemo(() => chapterEventDateDisplay(data), [data]);

  return (
    <Box paddingY={2}>
      <WLText as="b">{data.name}</WLText>
      <WLText>{data.location}</WLText>
      <WLText>{dttm}</WLText>

      <WLText marginTop={4}>{data.detail}</WLText>
    </Box>
  );
};

const Events: React.FC<EventsProps> = ({ state, chapterNumber }) => {
  const events = useChapterEvents(state, chapterNumber);

  let content;
  if (!events) {
    content = <CircularProgress isIndeterminate color="blue.800" />;
  } else if (Object.keys(events).length === 0) {
    content = (
      <WLText>No events are currently planned for this chapter.</WLText>
    );
  } else {
    const months = Object.keys(events);
    content = months.map((month, index) => (
      <React.Fragment key={index}>
        <WLDivider colorScheme={DividerColorScheme.GRAY} as="h3">
          {month}
        </WLDivider>
        <WLBox marginTop={4}>
          {events[month].map((e, index) => (
            <Event key={index} data={e} />
          ))}
        </WLBox>
      </React.Fragment>
    ));
  }

  return (
    <WLBox width="full" paddingTop="2rem">
      <WLH2>Chapter Events</WLH2>
      <WLBox>{content}</WLBox>
    </WLBox>
  );
};

export default Events;
