import { ChapterEventResponse } from '../../apis';
import { Officer } from './types';

export const chapterEventDateDisplay = (chapterEvent: ChapterEventResponse) => {
  const start =
    chapterEvent.startDate &&
    `${new Date(chapterEvent.startDate).toLocaleDateString('en-US')}`;

  const end =
    chapterEvent.endDate &&
    `${new Date(chapterEvent.endDate).toLocaleDateString('en-US')}`;

  let dateToReturn = start;

  if (chapterEvent.startTime) {
    dateToReturn = `${dateToReturn} ${chapterEvent.startTime}`;
  }

  if (
    chapterEvent.endTime ||
    (chapterEvent.endDate && chapterEvent.endDate !== chapterEvent.startDate)
  ) {
    dateToReturn = `${dateToReturn} -`;
  }

  if (chapterEvent.endDate && chapterEvent.endDate !== chapterEvent.startDate) {
    dateToReturn = `${dateToReturn} ${end}`;
  }

  if (chapterEvent.endTime) {
    dateToReturn = `${dateToReturn} ${chapterEvent.endTime}`;
  }

  return dateToReturn;
};

export const buildChapterOfficerName = (officer: Officer) => {
  return [officer.firstName, officer.lastName, officer.suffixName]
    .filter((segment) => !!segment)
    .join(' ');
};
