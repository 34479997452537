import React from 'react';

import { ApiConfiguration, ChapterApi, ChapterEventResponse } from '../apis';
import { monthNames } from '../utils/date.util';

const useChapterEvents = (
  state: string | null | undefined,
  chapterNumber: number | null | undefined
): null | {
  [key: string]: ChapterEventResponse[];
} => {
  const [events, setEvents] = React.useState<null | {
    [key: string]: ChapterEventResponse[];
  }>(null);

  React.useEffect(() => {
    setEvents(null);
    if (state && chapterNumber) {
      const api = new ChapterApi(
        new ApiConfiguration({
          basePath: process.env.GATSBY_PUBLIC_API_PATH,
        })
      );
      api
        .chapterEventsPost({
          getChapterEventsRequest: {
            state,
            chapterNumber,
          },
        })
        .then((events) => {
          const groupedEvents = events.reduce((prev, current) => {
            const month =
              current.startDate &&
              monthNames[new Date(current.startDate).getMonth()];

            if (month) {
              if (prev[month]) {
                prev[month].push(current);
              } else {
                prev[month] = [current];
              }
            }

            return prev;
          }, {} as any);

          setEvents(groupedEvents);
        })
        .catch((error) => {
          setEvents({});
        });
    }
  }, [state, chapterNumber]);

  return events;
};

export { useChapterEvents };
