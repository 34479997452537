import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';

import { WLBox, WLH2, WLLink, WLText } from '../../../design-library';
import { formatPhoneNumber } from '../../../utils/formatting.utils';
import { Officer } from '../types';
import { buildChapterOfficerName } from '../utils';
interface OfficersProps {
  officers: Officer[] | null | undefined;
}

// TODO: Add officer email
const FeaturedOfficer: React.FC<{ officer: Officer }> = ({ officer }) => (
  <WLBox marginTop={2}>
    <WLText>
      <strong>{officer.position}:</strong>
      {`  ${buildChapterOfficerName(officer)}`}
    </WLText>
    <div>
      {officer.phone && (
        <WLLink to={`tel:${officer.phone}`}>
          {formatPhoneNumber(officer.phone)}
        </WLLink>
      )}
    </div>
  </WLBox>
);

const Officers: React.FC<OfficersProps> = ({ officers }) => {
  let officerContent,
    president,
    secretary,
    coa,
    coaContent,
    sales,
    salesContent;
  if (!officers || !officers.length) {
    officerContent = <WLText>No chapter officers listed.</WLText>;
  } else {
    president = officers.find((officer) => officer.position === 'President');

    secretary = officers.find((officer) => officer.position === 'Secretary');

    coa = officers.filter(
      (officer) => officer.position === 'Community Outreach Advisor'
    );

    if (coa.length === 0)
      coaContent = <WLText>No chapter officers listed.</WLText>;

    sales = officers.filter(
      (officer) =>
        officer.position === 'Recruiting Sales Manager' ||
        officer.position === 'Regional Director'
    );

    if (sales.length === 0)
      salesContent = <WLText>No chapter officers listed.</WLText>;

    const theOtherOfficers = officers.filter(
      (officer) =>
        officer.position &&
        ![
          'Secretary',
          'President',
          'Community Outreach Advisor',
          'Recruiting Sales Manager',
          'Regional Director',
        ].includes(officer.position)
    );

    officerContent = theOtherOfficers.map((officer, index) => (
      <WLBox key={index}>
        <strong>{officer.position}:</strong>
        {`  ${buildChapterOfficerName(officer)}`}
      </WLBox>
    ));

    coaContent = coa.map((officer, index) => (
      <WLBox key={index}>
        <strong>{officer.position}:</strong>
        {`  ${buildChapterOfficerName(officer)}`}
      </WLBox>
    ));

    salesContent = sales.map((officer, index) => (
      <WLBox key={index}>
        <strong>{officer.position}:</strong>
        {`  ${buildChapterOfficerName(officer)}`}
      </WLBox>
    ));
  }

  return (
    <>
      <WLBox width="full" paddingTop="2rem">
        <WLH2>Chapter Officers</WLH2>
        <WLBox>
          {president && <FeaturedOfficer officer={president} />}
          {secretary && <FeaturedOfficer officer={secretary} />}
        </WLBox>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacingY={2} marginY={4}>
          {officerContent}
        </SimpleGrid>
      </WLBox>
      <WLBox width="full" paddingTop="2rem">
        <WLH2>Community Outreach Advisor</WLH2>
        <WLBox>{coaContent}</WLBox>
      </WLBox>
      <WLBox width="full" paddingTop="2rem">
        <WLH2>Sales Associates</WLH2>
        <WLBox>{salesContent}</WLBox>
      </WLBox>
    </>
  );
};

export default Officers;
