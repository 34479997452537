import { Box } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { ChapterByIdQuery } from '../../graphql-types';
import { ChapterEvents, ChapterOfficers } from '../components/Chapter';
import Layout from '../components/Layout';
import {
  WLBreadcrumb,
  WLContainer,
  WLDivider,
  WLH1,
  WLH2,
  WLLink,
  WLText,
} from '../design-library';
import { DividerColorScheme } from '../design-library/Divider';
import { formatName } from '../utils/formatting.utils';

interface ChapterTemplateProps extends PageProps<ChapterByIdQuery> {}

const ChapterTemplate: React.FC<ChapterTemplateProps> = ({
  location,
  data,
}) => {
  const chapter = data.chapter!;

  if (!chapter) {
    return null;
  }

  return (
    <Layout
      seo={{
        title:
          `Chapter ${chapter.chapterNumber}, ${chapter.state}` || undefined,
        description:
          `Information about the officers and events for Chapter ${chapter.chapterNumber}, ${chapter.state}` ||
          undefined,
        slug: location.pathname || undefined,
      }}
      currentPath={location.pathname}
    >
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
      >
        <WLBreadcrumb
          entries={[
            { label: 'Home', link: '/' },
            { label: 'Find Chapter', link: '/find/chapter/' },
            { label: 'Chapter' },
          ]}
        />
      </WLContainer>
      <WLContainer width="NARROW" paddingY={8} paddingX={{ base: 2, md: 0 }}>
        <WLH1 width="full">
          Welcome to WoodmenLife Chapter {chapter.chapterNumber} in{' '}
          {formatName(chapter.city)}, {chapter.state}
        </WLH1>

        <WLDivider colorScheme={DividerColorScheme.GRAY} />

        <ChapterOfficers officers={chapter.officers} />

        <ChapterEvents
          state={chapter.state}
          chapterNumber={chapter.chapterNumber}
        />

        <Box width="full" paddingY={4}>
          <WLH2>What is a Chapter?</WLH2>
          <WLText>
            Our WoodmenLife chapter is made up of members from our local
            communities who share a commitment to Family, Community and Country.
            Members belong to a chapter in the area in which they live, and each
            chapter elects their own officers.
          </WLText>
          <br />
          <WLText>
            Through our community-focused, family-oriented chapter, members can
            participate in our social, civic, charitable, educational,
            patriotic, and voluntary service activities. We partner with local
            schools, fire departments and other charitable organizations to help
            make our community better for everyone.
          </WLText>
          <br />
          <WLText>
            Chapters across the country plan a diverse range of activities. A
            local chapter might host a backpack stuffing event for schools, help
            build a local Habitat for Humanity home or hold a bake sale to
            benefit its volunteer fire department. Our chapter activities create
            family time and are about having fun, making new friends and helping
            others.
          </WLText>
        </Box>

        <Box width="full" paddingY={4}>
          <WLH2>Fraternalism</WLH2>
          <WLText>
            The idea of fraternalism has been around for thousands of years. It
            began with individuals who shared a commitment of caring for each
            other, helping those in times of need, and making their community a
            better place to live.
          </WLText>
          <br />
          <WLText>
            Those same beliefs hold true today at WoodmenLife. Fraternalism is
            at the very heart of our local chapter.
          </WLText>
        </Box>

        <Box width="full" paddingY={4}>
          <WLH2>How to join a chapter</WLH2>
          <WLText>
            When you become a member of the WoodmenLife family, you
            automatically become a member of your local chapter and can enjoy
            the many member activities and community service events chapters
            sponsor. Additionally, you gain access to a wide range of valuable
            extras<sup>1</sup>.
          </WLText>
          <br />
          <WLText>
            To find out more about WoodmenLife products, services and becoming a
            member,{' '}
            <WLLink to="/find/rep/">local WoodmenLife Representative</WLLink>.
            For additional inquiries, contact Customer Service at{' '}
            <a href="tel:1-800-225-3108">1-800-225-3108</a>.
          </WLText>
        </Box>

        <Box width="full" paddingY={4}>
          <WLText as="small">
            1. WoodmenLife Extras are available to members. An individual
            becomes a member by joining our shared commitment to family,
            community and country, and by purchasing a WoodmenLife product.
            These extras are not contractual, are subject to change and have
            specific eligibility requirements, such as length of membership,
            number of qualifying members in household and/or qualifying event.
          </WLText>
        </Box>
      </WLContainer>
    </Layout>
  );
};

export default ChapterTemplate;

export const chapterQuery = graphql`
  query ChapterById($chapterNumber: Int!, $state: String!) {
    chapter(chapterNumber: { eq: $chapterNumber }, state: { eq: $state }) {
      chapterNumber
      city
      state
      zipCode
      officers {
        position
        firstName
        lastName
        suffixName
        phone
      }
    }
  }
`;
